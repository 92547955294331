/** @format */

body {
  background-color: #000000;
  background-image: url("media/images/001.png");
  background-position: center center;
  background-size: 100% auto;
  padding: 0% 0% 3% 0%;
  backdrop-filter: blur(2px);
}

::-webkit-scrollbar {
  width: 0;
}
.collapse {
  visibility: visible !important;
}
h4,
h3 {
  -webkit-text-stroke-width: 2px;
  stroke-width: 2px;
  -webkit-text-stroke-color: #000;
  stroke: #000;
  text-shadow: 0px 0px 17px rgba(0, 0, 0, 0.97);
}

@keyframes reduceSize {
  0% {
    transform: scale(1);
    /* Initial size */
  }

  100% {
    transform: scale(0.8);
    /* Reduced size */
  }
}

.fufu-btn {
  font-size: 15px;
  font-weight: 500;
  fill: #ffffff;
  color: #ffffff;
  background-color: transparent;
  background-image: linear-gradient(156deg, #3730ff 0%, #f2295b 100%);
  border-radius: 25px 25px 25px 25px;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.5);
  transition: transform 0.3s ease;
cursor: pointer;
  &:hover {
    animation: reduceSize 0.3s forwards;
  }
}
.modal-content {
  width: calc(330px + 10 * ((100vw - 320px) / 680));
  border-radius: 11px !important;
  background-image: linear-gradient(90deg, #000000 0%, #000000 100%) !important;
  color: #ffffff;
}

.modal-body {
  border-radius: 9px !important;
  button {
    font-size: 15px;
    font-weight: 500;
    fill: #ffffff;
    color: #ffffff;
    background-color: transparent;
    background-image: linear-gradient(156deg, #3730ff 0%, #f2295b 100%);
    border-radius: 25px 25px 25px 25px;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.5);
    transition: transform 0.3s ease;

    &:hover {
      animation: reduceSize 0.3s forwards;
    }
  }
}
.hero_sec {
  // animation: jello 2s linear 0s infinite alternate forwards;
  animation: shake 2s linear 0s infinite alternate forwards;

  .overlay {
    background-image: url(https://i.imgur.com/ems6JSW.gif);
    background-position: center center;
    background-repeat: repeat;
    background-size: cover;
    opacity: 1;
    // mix-blend-mode: screen;

    h2 {
      -webkit-text-stroke-width: 2px;
      stroke-width: 2px;
      -webkit-text-stroke-color: #000;
      stroke: #000;
      text-shadow: 0px 0px 17px rgba(0, 0, 0, 0.97);
    }
  }
  button {
    font-size: 15px;
    font-weight: 500;
    fill: #ffffff;
    color: #ffffff;
    background-color: transparent;
    background-image: linear-gradient(156deg, #3730ff 0%, #f2295b 100%);
    border-radius: 25px 25px 25px 25px;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.5);
    transition: transform 0.3s ease;

    &:hover {
      animation: reduceSize 0.3s forwards;
    }
  }
}

.about_sec {
  animation: plus 2s linear 0s infinite alternate forwards;
  background-color: transparent !important;
  background-image: linear-gradient(
    90deg,
    #000000 0%,
    #12458d73 100%
  ) !important;

  .overlay {
    background-image: url("media/images/001.png");
    background-position: top center;
    background-size: cover;
    opacity: 1 !important;
    mix-blend-mode: color-dodge;
    filter: brightness(100%) contrast(100%) saturate(100%) blur(8.7px)
      hue-rotate(0deg);
  }

  .left_txt {
    h4,
    h3 {
      -webkit-text-stroke-width: 2px;
      stroke-width: 2px;
      -webkit-text-stroke-color: #000;
      stroke: #000;
      text-shadow: 0px 0px 17px rgba(0, 0, 0, 0.97);
    }

    button {
      font-size: 15px;
      font-weight: 500;
      fill: #ffffff;
      color: #ffffff;
      background-color: transparent;
      background-image: linear-gradient(156deg, #3730ff 0%, #f2295b 100%);
      border-radius: 25px 25px 25px 25px;
      box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.5);
      transition: transform 0.3s ease;

      &:hover {
        animation: reduceSize 0.3s forwards;
      }
    }
  }
}
.animate-timer {
  position: relative;
  animation: animatetop 1s infinite;
}
@keyframes animatetop {
  from {
    top: -50px;
  }
  to {
    top: 20px;
  }
}

@keyframes plus {
  0% {
    animation-timing-function: ease-out;
    transform: scale(1);
    transform-origin: center center;
  }

  10% {
    animation-timing-function: ease-in;
    transform: scale(0.91);
  }

  17% {
    animation-timing-function: ease-out;
    transform: scale(0.98);
  }

  33% {
    animation-timing-function: ease-in;
    transform: scale(0.87);
  }

  45% {
    animation-timing-function: ease-out;
    transform: scale(1);
  }
}

.section {
  background-color: transparent;
  background-image: linear-gradient(0deg, #034119 0%, #ff088673 100%);

  .overlay {
    background-image: url("media/images/001.png");
    background-position: top center;
    background-size: cover;
    opacity: 1 !important;
    mix-blend-mode: color-dodge;
    filter: brightness(100%) contrast(100%) saturate(100%) blur(8.7px)
      hue-rotate(0deg) !important;
  }

  .right_txt {
    h4,
    h3 {
      -webkit-text-stroke-width: 2px;
      stroke-width: 2px;
      -webkit-text-stroke-color: #000;
      stroke: #000;
      text-shadow: 0px 0px 17px rgba(0, 0, 0, 0.97);
    }
  }
}
.imgg {
  animation: wobble 300ms linear 0s infinite alternate forwards;
}
@keyframes wobble {
  0% {
    transform: scale(0.5);
  }

  100% {
    transform: scale(1);
  }
}
.tokenomics {
  background-color: transparent;
  background-image: linear-gradient(0deg, #000000 0%, #128d2e73 100%);
  animation: shake 2s linear 0s infinite alternate forwards;
  .overlay {
    background-image: url("media/images/001.png");
    background-position: top center;
    background-size: cover;
    opacity: 1 !important;
    mix-blend-mode: color-dodge;
    filter: brightness(100%) contrast(100%) saturate(100%) blur(8.7px)
      hue-rotate(0deg) !important;
  }
}
@keyframes shake {
  0%,
  100% {
    transform: rotate(0deg);
    transform-origin: 50% 50%;
  }

  10% {
    transform: rotate(8deg);
  }

  20%,
  40%,
  60% {
    transform: rotate(-10deg);
  }

  30%,
  50%,
  70% {
    transform: rotate(10deg);
  }

  80% {
    transform: rotate(-8deg);
  }

  90% {
    transform: rotate(8deg);
  }
}
.shakee {
  animation: skaje 600ms linear 0s infinite alternate forwards;
}
@keyframes skaje {
  0%,
  100% {
    transform: rotate(0deg);
    transform-origin: 50% 0;
  }

  10% {
    transform: rotate(2deg);
  }

  20%,
  40%,
  60% {
    transform: rotate(-4deg);
  }

  30%,
  50%,
  70% {
    transform: rotate(4deg);
  }

  80% {
    transform: rotate(-2deg);
  }

  90% {
    transform: rotate(2deg);
  }
}
.buy_sec {
  background-color: transparent;
  background-image: linear-gradient(180deg, #000000 0%, #8d127173 100%);
  animation: scale 400ms linear 0s infinite alternate forwards;
  .overlay2 {
    box-shadow: 0px 11px 10px 0px rgba(0, 0, 0, 0.5);
    background-image: url("../public/assets/images/1.png"),
      linear-gradient(150deg, #4bffef 0%, #010925 100%) !important;
    background-position: center center;
    background-size: cover;
    opacity: 0.78 !important;
    border-radius: 25px;
  }

  button {
    font-size: 15px;
    font-weight: 500;
    fill: #ffffff;
    color: #ffffff;
    background-color: transparent;
    background-image: linear-gradient(156deg, #3730ff 0%, #f2295b 100%);
    border-radius: 25px 25px 25px 25px;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.5);
    transition: transform 0.3s ease;

    &:hover {
      animation: reduceSize 0.3s forwards;
    }
  }
}
@keyframes scale {
  0% {
    transform: scale(0.7);
  }

  100% {
    transform: scale(1);
  }
}
.roadmap {
  background-color: transparent;
  background-image: linear-gradient(0deg, #000000a8 0%, #8d671273 100%);

  .card_1 {
    background-color: transparent;
    background-image: linear-gradient(210deg, #4bff66 0%, #010925 100%);
    border-radius: 25px;
  }

  .card_2 {
    background-color: transparent;
    background-image: linear-gradient(210deg, #4b70ff 0%, #010925 100%);
    border-radius: 25px;
  }

  .card_3 {
    background-color: transparent;
    background-image: linear-gradient(210deg, #fe4bff 0%, #010925 100%);
    border-radius: 25px;
  }
}

@keyframes jello {
  0% {
    transform: scale3d(1, 1, 1);
  }

  30% {
    transform: scale3d(1.25, 0.75, 1);
  }

  40% {
    transform: scale3d(0.75, 1.25, 1);
  }

  50% {
    transform: scale3d(1.15, 0.85, 1);
  }

  65% {
    transform: scale3d(0.95, 1.05, 1);
  }

  75% {
    transform: scale3d(1.05, 0.95, 1);
  }

  100% {
    transform: scale3d(1, 1, 1);
  }
}
